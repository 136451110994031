<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ profile.username }}
            </p>
            <span class="user-status">{{ role }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item :to="{ name: 'account-setting' }" link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="SettingsIcon"
            class="mr-50"
          />
          <span>Settings</span>
        </b-dropdown-item> -->

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import loginService from "@/services/loginService.js";
import jwt_decode from "jwt-decode";
import usersService from "@/services/usersService.js";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    Locale,
    DarkToggler,
  },
  data() {
    return {
      profile: {},
      role: "",
    };
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    getProfileData() {
      var token = localStorage.getItem("token");
      var decoded = jwt_decode(token);
      
      if (decoded.role == "superadmin") {
        this.role = "Superadmin";
      } else if (decoded.role == "admin") {
        this.role = "Admin";
      } else if (decoded.role == "customer") {
        this.role = "Customer";
      } else if (decoded.role == "manager") {
        this.role = "Manager";
      } else {
        this.role = "User";
      }

      try {
        usersService
          .getdataByMe()
          .then((response) => {
            this.profile = response.data.data;
            //console.log(this.profile);
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    logout() {
      var token = localStorage.getItem("token");
      try {
        loginService
          .logout(token)
          .then((response) => {
            if (response.data.status == "error" || response.status == 401) {
              // this.$swal("เข้าสู่ระบบไม่สำเร็จ", "Error!", "error");
            } else {
              localStorage.removeItem("token");
              this.$router.push("/login");
            }
          })
          .catch();
      } catch (e) {
        throw e;
      }
    },
  },
  async created() {
    await this.getProfileData();
  },
};
</script>
