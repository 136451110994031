export default [
  // {
  //   title: "Dashboard",
  //   route: "dashboard",
  //   icon: "GridIcon",
  //   role: 'all',
  // },
  {
    title: "Device",
    route: "device",
    icon: "MonitorIcon",


    role: ['superadmin', 'admin'],
  },
  {
    title: "Dashboard",
    icon: "GridIcon",
    route: 'dashboard',
    role: ['customer','manager'],
  },
  {
    title: "Report",
    route: "report",
    icon: "FileTextIcon",
    role: 'all',
  },
  {
    title: "Customer",
    icon: "UsersIcon",
    route: 'customer',
    role: ['superadmin', 'admin'],
  },


  {
    title: "Setting",
    icon: "SettingsIcon",
    route: 'setting',
    role: ['customer','manager'],
  },
  {
    header: 'ข้อมูลพื้นฐาน',
    role: ['superadmin', 'admin'],
  },
  {
    title: "Product",
    icon: "PackageIcon",
    route: 'product',
    role: ['superadmin', 'admin'],
  },
  {
    title: "Users",
    icon: "UserIcon",
    route: 'users',
    role: ['superadmin', 'admin'],
  },

];
